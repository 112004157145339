<template>
  <div class="seeMore">
    <div class="seeMore-center">
      <div class="report" v-loading="loading" element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <Box>
          <div style="width: 100%">
            <div @click="back" class="back-button" style="float: right"></div>
            <div class="header">
              <div class="title"><span class="text">无人机作业报告</span></div>
            </div>
            <div class="body">
              <el-form
                ref="edit_form"
                v-if="edit"
                :rules="rules.edit_form"
                :model="edit_form"
                class="linear-form vertical edit-form"
              >
                <div style="width: 100%" class="aa">
                  <el-form-item label="任务名称" prop="taskTitle">
                    <el-input
                      v-model="edit_form.taskTitle"
                      placeholder="请输入任务名称"
                      clearable
                    />
                  </el-form-item>
                  <el-form-item label="任务类型" prop="cate">
                    <el-input
                      v-model="edit_form.cate"
                      placeholder="请选择任务类型"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="需求人" prop="name">
                    <el-input
                      v-model="edit_form.name"
                      placeholder="请输入需求人"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="审核人" prop="reportApplyUser">
                    <el-input
                      v-model="edit_form.reportApplyUser"
                      placeholder="请输入审核人"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="生成时间" prop="endTime">
                    <el-date-picker
                      size="mini"
                      v-model="edit_form.endTime"
                      placeholder="请选择生成时间"
                    />
                  </el-form-item>
                </div>
                <div style="color: #b6d6ff; font-size: 24px">一、飞行纪录</div>
                <div style="width: 100%" class="aa">
                  <el-form-item label="飞行架次" prop="sortieNum">
                    <el-input-number
                      v-model="edit_form.sortieNum"
                      placeholder="请输入飞行架次"
                      clearable
                    />
                  </el-form-item>
                  <el-form-item label="飞行时间" prop="date">
                    <el-date-picker
                      v-model="edit_form.date"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="飞行总里程(M)" prop="flightDistance">
                    <el-input-number
                      v-model="edit_form.flightDistance"
                      placeholder="请输入飞行总里程"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="飞行时长(min)" prop="flightTime">
                    <el-input-number
                      v-model="edit_form.flightTime"
                      placeholder="请输入飞行时长"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="运维人员" prop="operation">
                    <el-input
                      v-model="edit_form.operation"
                      placeholder="请输入运维人员"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="派单人员" prop="name">
                    <el-input
                      v-model="edit_form.name"
                      placeholder="请输入派单人员"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="起始位置" required prop="destination">
                    <el-input
                      v-model="edit_form.destination"
                      placeholder="请输入需求单位名称"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="任务成果">
                    <span class="color">{{
                      list.ais
                        .map((item) => item.topic + "共" + item.num + "次")
                        .join("、")
                    }}</span>
                  </el-form-item>

                  <el-form-item label="需求单位名称" prop="organizationName">
                    <el-input
                      v-model="edit_form.organizationName"
                      placeholder="请输入需求单位名称"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="报告编订人姓名" prop="reportApplyUser">
                    <el-input
                      v-model="edit_form.reportApplyUser"
                      placeholder="请输入报告编订人姓名"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item
                    label="报告编订人联系方式"
                    prop="reportApplyPhone"
                  >
                    <el-input
                      v-model="edit_form.reportApplyPhone"
                      placeholder="请输入联系方式"
                      clearable
                    />
                  </el-form-item>
                </div>
              </el-form>

               <!-- 详情 -->
              <el-form
                ref="edit_form"
                v-else
                :rules="rules.edit_form"
                :model="edit_form"
                class="linear-form vertical edit-form"
              >
                <div style="width: 100%" class="grap">
                  <el-form-item label="任务名称" prop="taskTitle">
                    <el-input
                      v-model="edit_form.taskTitle"
                      disabled
                      clearable
                    />
                  </el-form-item>
                  <el-form-item label="任务类型" prop="cate">
                    <el-input
                      v-model="edit_form.cate"
                      disabled
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="需求人" prop="name">
                    <el-input
                      v-model="edit_form.name"
                      disabled
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="审核人" prop="reportApplyUser">
                    <el-input
                      v-model="edit_form.reportApplyUser"
                      disabled
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="生成时间" prop="endTime">
                    <el-date-picker
                      size="mini"
                      v-model="edit_form.endTime"
                      disabled
                    />
                  </el-form-item>
                </div>
                <div style="color: #b6d6ff; font-size: 20px">一、飞行纪录</div>
                <div style="width: 100%; margin: 20px 0" v-if="list">
                  <div class="text">
                  飞行架次：{{ list.sortieNum }}
                  </div>
                  <div class="text">
                    飞行时间：{{
                      list.addtime && list.endTime
                        ? list.executeTime + "-" + list.endTime
                        : "暂无"
                    }}
                  </div>
                  <div class="text">
                    无人机：飞行总里程为{{
                      list.flightDistance
                    }}M，飞行总时长为{{ list.flightTime }}min
                  </div>
                  <div class="text">
                    <span>运维人员: {{ list.operation }}</span>
                    <span style="margin-left: 25px"
                      >派单人员：{{ list.handleUser }}</span
                    >
                  </div>
                  <div class="text">起始位置：{{ `${list.destination}-${list.origin}` }}</div>
                  <div class="text">
                    AI识别成果：
                    {{
                      list.ais
                        .map((item) => item.topic + "共" + item.num + "次")
                        .join("、")
                    }}
                  </div>
                  <div class="text">
                    需求单位名称：{{ list.organizationName }}
                  </div>
                  <div class="text">
                    报告编订人姓名以及联系方式：{{ list.reportApplyUser }}-{{
                      list.reportApplyPhone
                    }}
                  </div>
                </div>
              </el-form>
              <Table
                class="task-table"
                :columns="columns"
                :dataSource="dataSource"
              >
                <template #scene="{ row }">
                  <div>
                    <!-- <el-image
                      class="image"
                      :src="row.scene"
                      :preview-src-list="[row.scene]"
                    >
                    </el-image> -->
                    <SlotBox
              :key="`box_` + row.scene"
              :url="row.scene"
              :boxes="row.boxes"
            />
                  </div>
                </template>
              </Table>
            </div>
          </div>
        </Box>
      </div>
      <div class="Side" v-if="list">
        <div
          v-if="this.list.status === 1"
          class="box"
          @click="handle_report(list)"
        >
          <span class="iconfont icon-xiazai" title="下载"></span>
        </div>
        <div
          class="box"
          @click="onsp"
          v-if="this.list.status != 1 && user_info_hn.departmentId === 60"
        >
          <span class="iconfont icon-shenpi" title="审批"></span>
        </div>
        <div
          class="box"
          @click="onEdit"
          v-if="
            edit === false &&
            user_info_hn.departmentId === 60 &&
            (this.list.status === 0 || this.list.status === 2)
          "
        >
          <span class="iconfont icon-genggai" title="编辑"></span>
        </div>
        <div class="box" @click="sava" v-if="edit">
          <span class="iconfont icon-baocun" title="保存"></span>
        </div>
        <div class="box" @click="goAch">
          <span class="iconfont icon-ziliaoku" title="飞行成果"></span>
        </div>
      </div>
    </div>

    <el-dialog
      title="温馨提示"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="384px"
      class="taskapprove"
    >
      <div style="color: #cce8ff; text-align: center; font-size: 20px;">报告审批是否通过？</div>
      <div class="button">
        <div @click="handle_approve(1)" class="linear-btn">审批通过</div>
        <div @click="handle_approve(2)" class="linear-btn">审批不通过</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SlotBox from "../../visual/view/uav/warning/slot-box.vue";
import Box from "@/components/platform/common/box";
import Table from "@/components/common/table";
import API from "@/api";
import dayjs from "dayjs";
export default {
  components: {
    Box,
    Table,
    SlotBox
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      dialogVisible: false,
      edit: false,
      list: null,
      mapList: {
        status: {
          0: "待执行",
          1: "已完成",
          2: "执行中",
        },
        apply: {
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
      edit_form: {
        taskTitle: null,
        endTime: null,
        cate: null,
        name: null,
        reportApplyUser: null,
        flightDistance: null,
        flightTime: null,
        operation: null,
        destination: null,
        organizationName: null,
        reportApplyUser: null,
        reportApplyPhone: null,
        date: null,
        sortieNum: null
      },
      rules: {
        edit_form: {
          destination: [
            { required: true, message: '请输入起始位置', trigger: 'blur' }
          ],
          reportApplyPhone: [
            { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
          ],
        },
      },
      drd: {
        uav_type: [],
        mount_type: [],
        task_type: [],
        task_cate: [],
        proStatus: [
          { id: 1, label: "待处理", value: 0 },
          { id: 2, label: "处理完成", value: 1 },
          { id: 3, label: "无需处理", value: 2 },
        ],
      },

      columns: [
        { prop: "topic", label: "AI识别结果", width: "168px" },
        { prop: "scene", label: "视频帧/照片", slot: true, width: "168px" },
        { prop: "num", label: "目标数量", width: "168px" },
        { prop: "extra", label: "目标事物信息", width: "168px" },
      ],
      dataSource: [],
      loading: false
    };
  },
  methods: {
    async async_get() {
      this.loading = true
      const id = this.$route.params.id;
      const res = await API.REPORT.reportDetail(id);
      if(res.status === 1) {
        let { data } = res;
        this.list = data
        this.init_form(data)
        this.edit_form.date = [data.executeTime, data.endTime]
        this.dataSource = data.ais
        this.dataSource.map(item => {
          if(item.scores && item.topic === '车牌识别') {
            item.extra = item.scores.join('、')
          }
        })
        this.loading = false
      } else {
         this.$message.error(res.message)
      }
      
    },
    async drd_task_type() {
      try {
        let res = await API.TASK.GetDrdType();
        res = res.data;
        this.drd.task_type = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    onEdit() {
      this.edit = true;
    },
    init_form(data) {
      Object.keys(this.edit_form).forEach((key) => {
        this.edit_form[key] = data[key] || null;
      });
    },
    async handle_report(row) {
      const res = await API.TASK.GetReport({
        id: row.id,
      });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(res);
      a.download = `${row.taskTitle}.docx`;
      a.click();
    },
    onsp() {
      this.dialogVisible = true;
    },
    async handle_approve(num) {
      let res;
      if (num === 1) {
        res = await API.TASK.reportApply({ ids: [this.list.id], status: num });
      } else {
        res = await API.TASK.reportApply({ ids: [this.list.id], status: num });
      }
      if (res.status === 1) {
        this.$el_message("审批成功");
        this.dialogVisible = false;
        this.async_get();
      }
    },
    async sava() {
      this.$refs["edit_form"].validate(async (valid) => {
        if (valid) {
          const { date, ...rest } = this.edit_form;
          const params = {
            ...rest,
            executeTime: dayjs(date[0]).format("YYYY-MM-DD HH:mm:ss"),
            endTime: dayjs(date[1]).format("YYYY-MM-DD HH:mm:ss"),
            id: this.list.id,
          };
          const res = await API.TASK.updateReport(params);
          if (res.status === 1) {
            this.$el_message("创建成功");
            this.$refs["edit_form"].resetFields();
            this.dialogVisible = false;
            this.edit = false;
            this.async_get();
          }
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    goAch() {
      const id = this.$route.params.id;
      this.$router.push(`/platform/order/achievement/${id}`)
    }
  },
  mounted() {
    this.drd_task_type();
    this.async_get();
  },
};
</script>

<style lang="scss" scoped>
.seeMore {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  &-center {
    height: 991px;
    padding-top: 35px;
    display: flex;
    .report {
      width: 1288px;
      .header {
        padding-top: 4px;
        height: 36px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
          width: 528px;
          height: 28px;
          background: url(~@/assets/platform/device/header.png) no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            color: #ffffff;
            font-size: 18px;
          }
        }
      }
      .body {
        padding: 24px 73px 0;
        height: 100%;

        .color {
          color: #b6d6ff;
          font-size: 18px;
        }
        .text {
          color: #b6d6ff;
          font-size: 16px;
          line-height: 30px;
        }
        .image {
          width: 80px;
          height: 70px;
        }
      }
    }
    .Side {
      padding-top: 310px;
      padding-left: 14px;
      .box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        width: 52px;
        height: 52px;
        border: 1px solid #009aff;
        .iconfont {
          cursor: pointer;
          font-size: 24px;
          color: #9fc4ff;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .edit-form {
    .aa {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-form-item {
        width: calc(50% - 6px);
      }
    }

    .grap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-form-item {
        width: calc(50% - 60px);
      }
    }

    .linear-fields {
      width: 100%;
      margin-bottom: 20px;
      .el-form-item {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
.report .cpt-service-table .table-bd .table-tr {
  height: 80px;
}
.taskapprove {
  .button {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    padding: 28px 55px;
  }
}
.back-button {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: url(~@/assets/platform/message/task/details/closure.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.seeMore .seeMore-center .report .body {
  overflow-y: auto;
}
.seeMore .cpt-warning-slot-box {
  border: none;
  margin: 0;
  height: 70px;
}
</style>
